import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Views/Page/Page';

type Props = {
  data: {
    page: any;
    [key: string]: any;
  };
};

export const query = graphql`
  query($slug: String!) {
    page(slug: { eq: $slug }) {
      title
      slug
      excerpt
      body
    }
  }
`;

export default ({ data }: Props) => {
  const { page } = data;

  return <Page data={{ ...data, page }} />;
};
