import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../Layout/Layout';
import * as listStyles from '../../ItemList/itemList.module.css';
import * as styles from './page.module.css';

type PageProps = {
  data: {
    page: {
      title: string;
      slug: string;
      excerpt: string;
      body: string;
    };
  };
};

const Page = ({ data: { page } }: PageProps) => (
  // console.log('body', page.body)
  <Layout
    currentPage={{
      name: 'page',
      slug: page.slug,
      title: page.title,
      footer: 'WebView HTML',
    }}
  >
    <div className={`${styles.page} page-${page.title}`}>
      <div className={`${styles.pageHeader} ${listStyles.listHeader}`}>
        <h1 className={styles.pageTitle}>{page.title}</h1>
      </div>
      <div className={styles.pageContent}>
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
    </div>
  </Layout>
);
export default Page;
